@import "../variables";

@mixin home-impact-bg {
  background: url("../../images/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.2);
}


//BANNER
.home-intro {
  color: #000000;
}

.home-intro-alt {
  color: #ffffff;
}

.home-hero-image {
  position: relative;
  left: 3%;
}

.solution,
.home-partners-wrapper {
  h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.home-hero-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 90%;

  h1 {
    font-size: 56px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 10px;

    img {
      margin-top: -15px;
      height: 52px;
      width: 52px;
    }
  }

  h4 {
    font-weight: 300;
    font-size: 12px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: start;
    text-transform: uppercase;
  }

  p {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 1.5rem;
  }

  .underline {
    display: flex;
    justify-content: start;
    margin: 10px 0 15px;
  }
}

.get-started-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;

  h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 70px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 300;
    padding-right: 5rem;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 3.5rem;
  }
}

.banner-typewriter {
  display: inline-block;
}

//STATISTICS
.homepage-statistics {
  padding: 6rem 0;
}

//WHAT WE DO

.what-we-do-intro {
  text-align: center;
  padding: 8rem 0 0;
  color: $vlb-300-font;
  width: 70%;

  h1 {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 20px;
  }
}

.wwd-image-container {
  margin-top: 4rem;
  text-align: center;
  position: relative;
  z-index: 3;

  img {
    width: 60%;
  }
}

.home-other {
  position: relative;
  top: -14rem;
  margin-bottom: -14rem;
}

.what-we-do-wrapper {
  position: relative;
  z-index: 2;
}

.wwd-details {
  position: relative;
  padding: 20rem 0 9rem;
}

.wwd-card {
  min-height: 15rem;
  width: 100%;
  padding: 2rem 1.5rem;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  p {
    font-size: 13px;
    line-height: 24px;
    opacity: 0.9;
    font-weight: 300;
    margin-top: 1rem;
  }

  &--inactive {
    color: #ffffff;
  }

  &--active {
    color: #33355a;
    background-color: #ffffff;
    border-radius: 15px;
  }
}

.home-impact {
  position: relative;
  z-index: 2;
  padding: 6rem 0 15rem 1.5rem;
  background: $stb-300-color;
}

.home-impact-text {
  border-radius: 15px;
  background: #ffffff;
  padding: 4rem 3.1rem;
  opacity: 0.8;
  width: 91%;

  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
  }

  p {
    margin-top: 1rem;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 28px;
  }
}

.home-impact-image {
  height: 272px;
  width: 100%;
  background: $stb-300-color;
}

.mobile-impact-details {
  padding: 6rem 2rem;
  color: #ffffff;

  h1 {
    font-size: 32px;
    letter-spacing: 0.25px;
    width: 80%;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 2rem;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

//PARTNER WITH US
.home-partner-with-us {
  h1 {
    font-weight: 600;
    font-size: 44px;
    line-height: 50px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
  }
}


.partner-cards {
  position: relative;
  z-index: 1;

  animation: moveup 90s infinite;
  animation-timing-function: linear;
  animation-delay: -1s;
  animation-fill-mode: forwards;
}

@keyframes moveup {
  from {
    bottom: -800px;
  }

  to {
    bottom: 1500px;
  }
}

//PARTNERS
.home-partners-wrapper {
  padding: 0 0 7rem;

  h1 {
    font-weight: 600;
  }
}

.home-partners {
  text-align: center;
  width: 65%;
  padding-top: 5rem;

  h1 {
    margin-bottom: 0;
    font-size: 48px;
    line-height: '94%'
  }
}

.home-partner-with-us {
  background-color: #ffffff;
}

//TESTIMONIALS
.testimonials {
  position: relative;
  z-index: 2;
  padding: 14rem 0;

  h2 {
    font-size: 48px;
    font-weight: 600;
    color: #3e4462;
    margin-bottom: 4rem;
  }

  .testimonial-content {
    width: 85%;
    margin: 0 auto;
  }

  .testimonial-image-container {

    div {
      cursor: pointer;
      width: 100px;
      height: 100px;
      background-color: rgba(51, 53, 90, 0.7);
      border-radius: 50%;
    }
  }
}

//PRIVACY POLICY {
.container.my-8 {
  margin: 4rem auto;
}

.container.mb-8 {
  margin-bottom: 4rem;
}

.container.mt-4 {
  margin-top: 2rem;
}

//PARTNERS
.home-partners-wrapper {
  position: relative;
  z-index: 2;
  padding-bottom: 8rem;
}

.home-partners-logos {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  justify-content: space-evenly;
}

.home-partners-logos>div {
  grid-gap: 10px;
}

.partner-logo {
  mix-blend-mode: luminosity;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 58px;
  margin-top: 2rem;
}

.partner-logo:hover {
  cursor: pointer;
  mix-blend-mode: unset;
}

@media only screen and (max-width: 1025px) {
  .wwd-details {
    background-image: none !important;
  }

  .what-we-do-wrapper {
    background-image: none !important;
  }

  .home-hero-text {
    padding-left: 3rem;
    width: 75%;
  }
}

@media (max-width: 768px) {
  .home-partners-wrapper {
    padding-bottom: 3rem;
  }

  .statistic-column {
    margin-top: 2rem;
  }

  .home-hero-text {
    padding-left: 3rem;
    width: 85%;
  }

  .home-hero-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    width: 90%;
  }


  .get-started-text {
    padding: .8rem;
  }

  .home-other {
    top: -10rem;
    margin-bottom: -10rem;
  }

  .wwd-details {
    padding: 14rem 0 5rem;
  }

  .wwd-card {
    width: 100%;
  }

  .home-partners {
    width: 100%;
  }

  .home-partners-logos {
    grid-template-columns: auto auto auto;
  }

  .partner-logo {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .home-partners {
    padding-top: 0;
  }

  .home-intro {
    padding: 8rem 0 10rem;
  }

  .get-started-text {
    padding: 0;
  }

  .home-partners-wrapper {
    padding-top: 3rem;
  }

  .home-intro-alt {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .home-hero-text {
    padding: 0 4rem 0 1rem;
    width: 100%;

    h1 {
      font-size: 3rem;
      line-height: 35px;

      img {
        margin-top: -15px;
        height: 40px;
        width: 40px;
      }
    }

    p {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 1rem;
    }

    .underline {
      margin: 0 0 15px;
    }
  }

  .get-started-text {
    h1 {
      font-size: 25px;
      line-height: 36px;
    }

    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      padding-right: 6rem;
      margin-bottom: 2rem;
    }
  }

  .homepage-statistics {
    padding: 3rem 0 3rem 1rem;
  }

  .statistic-column {
    margin-top: 0;
  }

  .what-we-do-intro {
    width: 84%;
    padding: 4rem 0 0;

    h1 {
      font-size: 26px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .wwd-image-container {
    margin-top: 2rem;

    img {
      width: 80%;
    }
  }

  .home-other {
    top: -7rem;
    margin-bottom: -7rem;
  }

  .wwd-details {
    margin-top: 2rem;
    padding: 8rem 0 5rem;
    min-height: 32rem;
  }

  .home-partner-with-us {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .testimonials {
    padding: 5rem 0 !important;

    h2 {
      font-size: 26px;
      margin-bottom: 2rem;
    }
  }

  .testimonial-content {
    margin: auto;
  }

  .testimonial-body {
    margin-top: 0.5rem !important;
  }

  .testimonial-image-container {
    height: 320px !important;

    div {
      width: 60px !important;
      height: 60px !important;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .solution,
  .home-partners-wrapper {
    h1 {
      font-size: 22px;
      margin-bottom: 0;
      line-height: 21px;
      font-weight: 600;
    }
  }

  .home-partners-logos {
    grid-template-columns: auto auto auto;
  }

  .partner-logo {
    width: 100px;
    margin: auto;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .home-impact-text {
    h1 {
      width: 73%;
      font-size: 48px;
    }
  }
}

@media (min-width: 1024px) {
  .home-partner-with-us {
    display: flex;
    max-height: 55rem;
  }
}

@media (min-width: 576px) and (max-width: 990px) {

  .home-intro,
  .home-intro-alt {
    padding: 10rem 0 3rem 0;
  }

  .statistic-column {
    margin-top: 2rem;
  }

  .home-partner-text {
    width: 100%;
    padding-bottom: 4rem;
  }
}

@media (max-width: 990px) {
  .testimonials {
    padding: 7rem 0 9rem;
  }

  .testimonial-content {
    width: 100% !important;
  }

  .testimonial-text {
    margin-top: 2rem;
  }

  .home-partners {
    width: 100%;
  }

  .home-partner-text {
    width: 100%;
    padding-bottom: 4rem;
  }
}

@media screen and (min-width: 990px) and (max-width: 1280px),
screen and (max-height: 768px) {
  .home-hero-image {
    // height: 80vh;
  }
}

@media screen and (min-width: 990px) and (max-width: 1280px) {

  .home-intro,
  .home-intro-alt {
    padding: 8rem 0 3rem 0;
  }
}

@media (min-width: 1280px) {

  .home-intro-container,
  .home-impact {
    max-width: 1500px;
  }

  .home-impact {
    margin: 0 auto;
  }

  .home-intro,
  .home-intro-alt {
    display: flex;
    justify-content: center;
    padding: 8rem 0 3rem 0;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-max-device-pixel-ratio: 1.5) {
  .home-hero-image {
    // height: 100vh;
  }
}