@import "../variables";

@mixin blue-bg {
    background: url("../../images/join-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main {
    @include blue-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    min-height: calc(100vh - 6rem);

    .parent {
        padding: 10px;
        max-width: 345px;

        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 2rem;
        }
    }
}

.inputs {
    margin-top: "30px";
    input {
        border: 1px solid #bababa;
        border-radius: 200px;
        color: #909090;
        max-width: 333px;
        padding: 10px 14px;
        margin: 13px auto;

        &:hover,
        &:active,
        &:focus {
            border: 1px solid #407bff;
            color: #2e353a;
        }
    }
    button {
        width: 333px;
        background-color: #407bff;
        color: white;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 10px auto;
    }
    .instructions {
        font-size: 12px;
        line-height: 18px;
        color: #263238;
        text-align: center;
        margin: 10px 0;
    }
}

@media (max-width: 576px) {
    .main {
        margin-top: 3rem;
        min-height: calc(100vh - 3rem);
    }
    .inputs {
        button {
            width: 100%;
        }
    }
}
