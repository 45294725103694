.faq-container {
    margin: 9rem auto 6rem;
    max-width: 850px;

    h3 {
        font-weight: 600;
        font-size: 35px;
        line-height: 94%;
        text-align: center;
        color: #407BFF;
    }

    .underline {
        display: flex;
        justify-content: center;
        margin: 15px auto 35px;
    }


    .collapsibles {
        border-top: "1px solid #EBEBEB";
        padding: "30px auto";
        margin: "10px auto";
    }

    .question {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .answer {
        font-weight: 400;
        font-size: 13px;
        line-height: 32px;
    }
}



@media only screen and (max-width: 760px) {
    .faq-container {
        margin: 5rem auto 3.5rem;

        h3 {
            font-size: 25px;
        }

        .underline {
            margin: 12px auto 25px;
        }
    }
}