@import "../variables";

.blog-container {
    .blog-wrapper {
        color: #2B55B7;
        font-size: 53px;
        font-weight: 600;
        line-height: 88%;
        letter-spacing: -1.6px;

        @media only screen and (max-width: 760px) {
            font-size: 32px;
        }
    }

    .underline {
        display: flex;
        justify-content: start;
        margin: 25px 0 15px;
    }
}

@media (max-width: 576px) {
    .blog-container {
        .underline {
            margin: 15px 0 15px;
        }
    }
}

@media (max-width: 576px) {
    .home-intro.blog-container {
        padding: 8rem 0 2rem;
    }

}


.blog-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 40px;
}

.bg-blue {
    border-radius: 30px;
    background: #F5F7FC;
}

.blog-box {
    border-radius: 20px;
    background: #FFF;
    min-height: 348px;

    @media (max-width: 576px) {
        max-width: 280px;
        margin: auto;
    }

    img.blog-img {
        width: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .blog-content {
        padding: 18px 12px;

        h3 {
            color: #0E347E;
            font-size: 16px;
            font-weight: 600;
            line-height: 130.5%;
            letter-spacing: -0.48px;
            text-align: start;
        }

        p {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 130.5%;
            letter-spacing: -0.42px;
            text-align: start;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .blog-nav {
            display: flex;
            justify-content: flex-start;
            color: #2B55B7;
            font-size: 14px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: -0.56px;
            margin-top: 10px;
            text-decoration: none;
            cursor: pointer;

            img {
                margin-left: 6px;
            }
        }
    }
}