@import "../variables";

@mixin transition() {
  transition: all 0.7s cubic-bezier(0.2, 1, 0.22, 1);
}

.vl-button {
  font-weight: 500;
  font-size: 16px;
  border-radius: 200px;
  font-style: normal;
  border-color: unset;
  border-style: none;
  color: #263238;

  @include transition();

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-1.5px);
  }

  &--fullwidth {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border: 1px solid #909090;
    color: #909090;
  }

  //variants
  &--primary {
    background-color: $stb-200-color;
    color: $stw-100-color;
    border: 1px solid $stb-200-color;
  }

  &--secondary {
    background-color: $stw-100-color;
  }

  &--tetiary {
    background-color: $vlb-100-color;
  }

  &--outlined {
    color: $stw-100-color;
    background-color: transparent;
    border: 1px solid $stw-100-color;
    // font-weight: bold;
  }

  &--outlined-colored {
    color: $stb-200-color;
    background-color: transparent;
    border: 1px solid $stb-200-color;
  }

  &--outlined-colored-black {
    color: #000000;
    background-color: transparent;
    border: 1px solid #000000;
  }

  &--bordered {
    color: #407bff;
    background-color: transparent;
    border: 1px solid #407bff;
  }

  //sizes
  &--lg {
    padding: 10px 32px;
  }

  &--md {
    padding: 12px 24px;
  }

  &--sm {
    padding: 10px 20px;
  }

  &--nav {
    padding: 10px 20px;
    font-size: 14px;
    width: 120px;
  }

  &--little {
    padding: 6px 16px;
    font-size: 14px;
  }

  &--long {
    padding: 0rem 5.5rem;
    height: 48px;
  }
}

@media (max-width: 320px) {
  .vl-button {
    font-size: 12px;
  }
}