@import "../variables";

@mixin blue-bg-plan {
    background: url("../../images/bg-pricing.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-pricing {
    @include blue-bg-plan;
}

.main-container {
    display: flex;
    justify-content: center;
    margin: auto 3rem;
    margin-top: 6rem;
    border-radius: 50px;

    .parent {
        padding: 4rem 5px 1rem;

        .button-container {
            margin-top: 2rem;
            margin-bottom: 3rem;
        }

        .tab-buttons {
            border-radius: 38.25px;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(9px);
            border-radius: 749.25px;
            padding: 12px;
            max-width: fit-content;

            .button-selected {
                background: #FFFFFF;
                color: #000;
            }

            .button-unselected {
                border: 1px solid #FFFFFF;
                background: transparent !important;
                color: #fff;
            }

            .button-unselected.vl-button:hover,
            .button-selected.vl-button:hover {
                transform: none !important;
            }

            .vl-button--primary {
                border: 1px solid #ffffff !important;
            }

        }

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            .col-md-3 {
                width: 50% !important;
            }

            .compare-section td .subtitle {
                display: none !important;
            }
        }

        h1 {
            font-weight: 600;
            font-size: 53px;
            line-height: 107%;
            text-align: center;
            letter-spacing: -0.06em;
            color: #ffffff;
        }

        h3 {
            margin: 20px auto;
            font-weight: 400;
            font-size: 18px;
            line-height: 36px;
            letter-spacing: -0.04em;
            text-align: center;
            color: #ffffff;
        }

        .card {
            height: 430px;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
            max-width: 350px;
            margin: auto;
            margin-top: 5px;
            padding-top: 18px;
            padding-bottom: 18px;
            margin-bottom: 20px;
            position: relative;
            background: rgba(255, 255, 255, 0.15);
            border: 1px solid rgba(255, 255, 255, 0.34);
            backdrop-filter: blur(24px);
            border-radius: 30px;

            &:hover {
                transition: all .5s ease-in-out;
                transform: scale(1.1);
            }

            .checked {
                position: absolute;
                top: -15px;
                right: -5px;
            }

            h2 {
                font-weight: 500;
                font-size: 33px;
                // line-height: 72px;
                margin: 12px 0 15px;

                color: #ffffff;
                padding-left: 18px;
                padding-right: 18px;
            }

            h4 {
                font-weight: 400;
                font-size: 22px;
                margin-bottom: 0;
                letter-spacing: -0.04em;
                padding-left: 18px;
                padding-right: 18px;
            }

            .caption {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                padding-left: 18px;
                padding-right: 18px;
                padding-bottom: 10px;
                color: #FFFFFF;
                opacity: 0.5;
            }

            .features {
                padding: 13px 18px;

                .feature {
                    margin: 8px auto 8px 0;

                    img {
                        margin-right: 5px;
                    }
                }
            }

            .action {
                position: absolute;
                right: 0;
                bottom: 0;
                margin-bottom: 15px;
                width: 100%;
                padding: 0.5rem 1rem;
            }

            .vl-button--bordered {
                color: #ffffff !important;
                border: 1px solid #ffffff !important;

                // font-weight: bold;
                &:hover {
                    background: #FFFFFF !important;
                    color: #000 !important;
                }
            }
        }
    }
}

.compare-section {
    margin: 7rem auto 2rem;

    h3 {
        font-weight: 600;
        font-size: 35px;
        line-height: 94%;
        text-align: center;
        color: #407BFF;
    }

    .underline {
        display: flex;
        justify-content: center;
        margin: 15px auto 35px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 5px auto;

        .vl-button--lg {
            padding: 10px 20px;
        }

        .vl-button--lg:hover {
            background-color: #407BFF !important;
            color: #FFFFFF !important;
            border: 1px solid #407BFF !important;
        }

        .vl-button {
            font-size: 13px;
        }
    }

    /* Zebra striping */
    tr:nth-of-type(odd),
    td:nth-of-type(even) {
        background: rgba(234, 238, 248, 0.5);
    }

    tr:nth-of-type(odd) {
        border-radius: 20px;
    }

    tr:last-child {
        background: #fff;
    }

    th {
        background: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #407bff;
        padding: 10px;
    }

    td {
        padding: 10px;
        text-align: left;
        font-size: 14px;
        color: #000000;
        height: 80px;
        border-bottom: 10px solid #fff;
        /* 	border: 1px solid #ccc;  */

        @media only screen and (min-device-width: 768px) {
            text-align: center;
        }

        &:first-child {
            font-weight: 600;
            font-size: 14px;

            @media only screen and (min-device-width: 768px) {
                text-align: left;
                width: 30%;
            }
        }

        &.bctn {
            background: #ffffff !important;
        }

        .subtitle {
            font-weight: 500 !important;
            font-size: 12px !important;
        }
    }

    tr:first-child {
        text-align: center;
    }

    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */
    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
        table {
            width: 100%;
        }

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            display: flex;
            align-items: center;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 35%;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            /* Label the data */
            content: attr(data-column);

            color: #000;
            font-weight: bold;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .compare-section td .subtitle {
        display: none !important;
    }
}


@media only screen and (max-width: 760px) {
    .main-container {
        margin: 6rem 1rem auto;
    }

    .main-container .parent {
        padding: 2rem 5px 1rem;
    }

    .main-container .parent h3 {
        margin: 6px auto;
    }

    .main-container .parent .button-container {
        margin: 1rem auto;
    }

    .main-container .parent h1 {
        font-size: 32px;
    }

    .main-container .parent .card {
        margin-top: 25px;
    }

    .vl-button--lg {
        padding: 7px 14px;
        font-size: 13px;
    }

    .compare-section td .subtitle {
        display: none !important;
    }

    .compare-section {
        margin: 5rem auto 1.5rem;

        h3 {
            font-size: 25px;
        }

        .underline {
            margin: 12px auto 25px;
        }

    }
}