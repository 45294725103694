@import "../variables";

@mixin left-image-bg {
    background: url("../../images/Auth/LeftBanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

@mixin right-image-bg {
    background: url("../../images/Auth/RightBanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.auth-intro-first {
    @include left-image-bg;
}

.auth-intro-second {
    @include right-image-bg;
}

.text-section {
    height: 100%;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    height: calc(100vh - 6rem);

    .full-page {
        padding: 0;
        margin: 0;
        height: 100%;
        max-width: 100vw;
        color: white;
    }
}

@media (max-width: 576px) {
    .main {
        margin-top: 3rem;
        min-height: calc(100vh - 3rem);
    }
}

.auth-hero-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    h1 {
        font-size: 61px;
        font-weight: bold;
        line-height: 70px;
        margin-top: 60px;
    }

    p {
        font-weight: 300;
        padding-right: 5rem;
        font-size: 17px;
        line-height: 30px;
        margin-bottom: .5rem;
    }
}

@media (max-width: 576px) {
    .auth-intro {
        padding: 3rem 0;
    }

    .auth-hero-text {
        padding: 0 4rem 0 1rem;
        width: 100%;
        h1 {
            font-size: 25px;
            line-height: 36px;
        }

        p {
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            padding-right: 6rem;
            margin-bottom: 2rem;
        }
    }
}

@media (min-width: 1024px) {
    .auth-intro {
        min-height: 100vh;
    }
}

@media (min-width: 576px) and (max-width: 990px) {
    .auth-intro {
        padding: 10rem 0 3rem 0;
    }
}

@media screen and (min-width: 990px) and (max-width: 1280px) {
    .auth-intro {
        padding: 8rem 0 7rem 0;
    }
}

@media (min-width: 1280px) {
    .auth-intro {
        display: flex;
        justify-content: center;
        padding: 8rem 0 7rem 0;
    }
}
