@import "../variables";

.slider-card {
  transition: all .5s ease-in-out;
}

.slider-card.active {
  opacity: 1;
}

.slider-card.inactive {
  opacity: 0;
  display: none;
}

.feature-card {
  border-radius: 80px;
  max-width: 100%;
  display: flex;
  justify-content: center;
}



.about-bg {
  background-image: url('../../images/Home/pattern-left-top.png');
  background-position: right bottom;
  background-repeat: no-repeat;
}

.about-intro-text {
  width: 52%;
  color: #ffffff;

  h2 {
    font-weight: 600;
    font-size: 48px;
  }

  p {
    margin-top: 2rem;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
  }
}

.our-company {
  padding: 10rem 0 4rem;

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.2px;

    color: #33355a;
  }

  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    color: $vlb-300-font;
  }
}

.about-banner-image {
  position: relative;
  top: -40rem;
  z-index: 2;
  margin-bottom: -15rem;
}

.our-goal {
  .heading {
    display: flex;
    justify-content: space-around;
    margin: 3rem auto 1.5rem;
    max-width: 1000px;
  }

  .title {
    cursor: pointer;
    font-weight: 400;
    font-size: 26px;
    font-style: normal;
    max-width: 20%;
    color: #505D67;
    text-align: center;
    letter-spacing: -0.04em;
  }

  .title.active {
    font-weight: 600 !important;
    background: rgba(56, 95, 187, 0.06);
    border-radius: 20px;
    padding: 5px 20px;
    max-width: fit-content;
    color: #2C6EEC !important;
  }

  .position {
    width: 100px;
    margin: 50px auto 10px;
  }
}


@media (min-width: 990px) {
  .about-card {
    .goal-text {
      padding-left: 5rem;
    }
  }
}

@media (max-width: 990px) {
  .our-goal {
    .title {
      font-size: 20px;
    }
  }
}


.about-card {
  justify-content: space-between;
  width: 100%;

  .goal-text {
    width: 55%;
    color: #ffffff;
    margin-top: -40px;

    img {
      margin-bottom: 2rem;
    }

    img.add {
      width: 60px;
      margin-right: 10px;
      margin-bottom: 0;
    }

    h3 {
      font-size: 3.5rem;
      font-weight: 700;
      margin-bottom: 2rem;
      max-width: 385px;
    }

    p {
      font-weight: 200;
      font-size: 18px;
      line-height: 30px;
    }
  }

  .goal-image-container {
    margin: 0;
    padding: 0;
    text-align: end;
    width: 45%;

    img {
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
    }
  }

  .goal-image-container-xs {
    margin: 0;
    padding: 0;
    width: 100%;

    img {
      border-bottom-right-radius: 58px;
      border-bottom-left-radius: 58px;
    }
  }
}

.goal-text-bg {
  background-image: url('../../images/Home/pattern-left-down.webp');
  background-position: 0;
  background-repeat: no-repeat;
}

.goal-text-BG {
  background-image: url('../../images/Home/card-bg.png');
  background-repeat: no-repeat;
  background-position: left;
}

.focus-section {
  padding: 8rem 0rem 6rem;

  .focus-about-intro-text {
    width: 66%;
    margin: 0 auto;

    h2 {
      font-weight: 600;
      font-size: 42px;
      color: #ffffff;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
    }
  }
}

.focus-items-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    margin-right: 1rem;
  }
}

.focus-item {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2rem 1rem 1rem;
  transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;

  h4 {
    color: $vlb-300-font;
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-weight: 200;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.048px;
    color: #031b30;
  }
}

.focus-item:hover {
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  transform: translateY(-3px) !important;
}

@media (max-width: 990px) {
  .about-intro-text {
    width: 100%;
    color: #fff;
  }

  .about-banner-carousel {
    position: relative;
    top: -50rem;
    z-index: 2;
    margin-bottom: -15rem;
  }

  .focus-items-container {
    grid-template-columns: repeat(2, 1fr);

    div {
      margin: 1rem;
    }
  }

  .about-card .goal-text {
    padding: 40px 20px;
    margin: 0;
  }

  .about-card .goal-image-container-xs img {
    width: 100%;
    margin-top: 25px;
  }
}

@media (max-width: 1100px) {
  .about-card .goal-image-container {
    height: 100%;
  }

  .about-card .goal-image-container img {
    width: -webkit-fill-available;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .our-goal .title {
    font-size: 18px;
  }

  .our-goal .title.active {
    border-radius: 8px;
    padding: 5px 10px;
  }

  .about-card .goal-text h3 {
    font-size: 2.5rem;
    max-width: fit-content;
  }

  .feature-card {
    border-radius: 65px;
  }

  .feature-container {
    padding-top: 5rem;
  }

  .about-card .goal-image-container {
    height: unset;
  }

  .about-card .goal-image-container-xs img {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .about-bg {
    background: none;
    margin-top: -3rem;
  }

  .experience-bg {
    margin-top: -10rem;
  }

  .flex-flip {
    flex-direction: column-reverse;
    display: flex;
  }

  .about-intro-text,
  .focus-about-intro-text {
    min-width: 100%;
  }

  .introduction {
    padding: 7rem 0 12rem;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
      line-height: 28px;
    }
  }

  .about-banner-carousel {
    z-index: 2;
    position: relative;
    top: -46rem;
    margin-bottom: -10rem;
  }

  .banner-carousel-image {
    width: 100%;
  }

  .our-company {
    padding: 5rem 0 4rem;

    h2 {
      font-weight: bold;
      font-size: 36px;
    }

    p {
      line-height: 26px;
      font-size: 16px;
    }
  }


  .feature-card {
    border-radius: 58px;
  }

  .our-goal {
    h3 {
      font-size: 36px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }

    img {
      width: 100%;
    }
  }

  .about-card {
    padding-top: 25px;

    .goal-text {
      width: 100%;
      margin-top: 0;
      margin: auto;
      text-align: start;
      padding: 5px 20px 25px;

      h3 {
        font-size: 25px;
        margin-bottom: 0;
      }
    }
  }

  .focus-items-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .our-goal {
    .title {
      font-size: 15px;
      max-width: 20%;
    }
  }

  .goal-text-BG {
    background-image: url('../../images/Home/card-bg-xs.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
}



@media screen and (max-width: 320px) {
  .about-banner-carousel {
    top: -50rem;
  }
}