.banner-container {
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.home-bg-intro {
    width: 100%;
    background-image: url('../../images/Home/pattern-right-down.png');
    background-position: 0 60px;
    background-repeat: no-repeat;
    background-size: auto 90%;
    position: relative;
}


.ticket-tag {
    position: absolute;
    bottom: -2rem;
    right: 0;
}

.home-btn-1 {
    margin-right: 20px;
    margin: 10px 20px 10px 0;
    width: 200px
}

.home-btn-2 {
    margin: 10px 0 10px;
    width: 200px
}

@media (max-width: 768px) {
    .home-btn-1 {
        margin-right: 10px;
        margin: 10px 10px 10px 0;
        width: auto
    }

    .home-btn-2 {
        margin: 10px;
        width: auto
    }

    .xs-width {
        width: 180px;
    }

    .ticket-tag {
        bottom: 1rem;
    }
    .banner-container {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}