@import "../variables";

.root {
  background: "transparent" !important;
  box-shadow: none !important;
  padding: 0.8rem 0;
  position: absolute;
  top: 0;

  a,
  .drop-down-arrow {
    color: #ffffff;
  }

  .nav-text a {
    color: #505D67;
  }

  &--light {
    background-color: #ffffff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) !important;

    a {
      color: $vlb-300-font;
    }

    .drop-down-arrow {
      color: $vlb-300-font;
    }
  }

  &--blue-bg {
    background-color: $vlb-500-color !important;

    a {
      color: $vlb-300-font;
    }

    .drop-down-arrow {
      color: $vlb-300-font;
    }
  }

  &--transparent-bg {
    background: "transparent" !important;

    a {
      color: $vlb-300-font;
    }

    .drop-down-arrow {
      color: $vlb-300-font;
    }
  }
}

@mixin nav-text-style() {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px
}

.nav-text {
  @include nav-text-style();
}

.nav-item {
  color: #505D67;
  @include nav-text-style();

  &--active,
  &:hover {
    color: $vlo-200-color !important;
  }
}

a,
a:hover {
  text-decoration: none;
}

.dropdown-menu {
  box-shadow: 5px 5px 5px #8888;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 260px !important;
  margin: 1rem .2rem .2rem;
  padding: 1rem;
  display: block;
}

.dropdown li {
  margin-right: 1.5rem;
}

.sub-menu-title {
  color: #0d0f11;
  font-size: 14px;
  padding: 0.7rem 0 0.7rem 0;
  font-weight: 500;
}

.sub-menu-item {
  color: #333E47;
  font-size: 14px;
  padding: 0.4rem 0 0.4rem 1rem;
  font-weight: 400;

  &--active,
  &:hover {
    background-color: #f4f5f6;
  }
}

.drawer {
  width: 100%;
}

.mobile-nav-root {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  padding-top: 10px;
}

.mobile-nav-header {
  padding: 1rem 2rem;
}

.mobile-sub-menu {
  margin: 1rem 0 0 1rem;
}

@media (min-width: 579px) {
  .header-item {
    margin-right: 2rem;
  }
}

@media (max-width: 960px) {
  .nav-item {
    color: #1d1d1d;
    font-weight: 600;
    margin-right: 0;

    &--active,
    &:hover {
      color: $vlo-200-color;
    }
  }

  .nav-items {
    gap: 0;

    .dropdown,
    a {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768px) {
  .dropdown li {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 0rem;
  }

  .nav-text {
    font-weight: 600;
  }

  .sub-menu-item {

    &--active,
    &:hover {
      background-color: transparent;
    }

    &--active {
      color: $vlo-200-color;
    }
  }

  .dropdown {
    margin-bottom: 2rem;
  }
}