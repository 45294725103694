@import "../variables";


.feature-container {
  margin: 2rem auto;
  .underline {
    display: flex;
    justify-content: start;
    margin: 25px 0;
  }

  .statistic-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 0px;
    margin-left: 4rem;
    margin-right: -4rem;

    .individual-card {
      margin: -8rem 0 0;
      padding: 0;

      &:hover {
        transition: all .5s ease-in-out;
        transform: scale(1.1);
        opacity: 999999999;
      }
    }
  }
}

.feature-container.solution {
  margin: auto;
  margin-top: 1rem
}

.features-card-container {
  background-position: center;
  border-radius: 50px;
  height: 402px;
  padding: 4rem;
  align-items: center;

  &:hover {
    cursor: pointer;
    transition: all .5s ease-in-out;
    transform: scale(1.1);
  }

  img {
    margin-top: -20px;
    height: 75px;
  }

  .tag {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0 5px;
  }

  h2 {
    font-size: 29px;
    font-weight: 600;
    line-height: 94%;
    letter-spacing: -1.8px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 130.5%;
    letter-spacing: -0.42px;
  }

  &--green {
    border-top-color: $vlg-100-color;
  }

  &--yellow {
    border-top-color: $vlo-100-color;
  }

  &--orange {
    border-top-color: $vlo-300-color;
  }

  &--blue {
    border-top-color: $vlb-300-color;
  }
}

.feature-BG {
  background-image: url('../../images/Home/discover-left.webp');
  background-position: 0 32rem;
  background-repeat: no-repeat;
}

.card-feature-BG {
  background-image: url('../../images/Home/discover-right.webp');
  background-position: top right;
  background-repeat: no-repeat;
}

.feature-text {
  color: #2B55B7;
  font-size: 40px;
  font-weight: 700;
  line-height: 94%;
  margin: 0 0 25px;
}

.tag-left {
  margin-left: -11rem;
}

@media (max-width: 576px) {
  .feature-text {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .tag-left {
    margin-left: -4rem;

    img {
      max-width: 225px;
    }
  }

  .card-feature-BG {
    background: none;
  }

  .feature-BG {
    background-image: url('../../images/Home/discover-left.webp');
    background-position: -6rem 2rem;
    background-repeat: no-repeat;
  }

  .feature-container {
    &.feature-BG {
      padding-top: 5rem !important;
    }

    .underline {
      margin: 8px auto 25px;
    }

    .statistic-column {
      margin: auto;
      margin-top: 2rem;

      .individual-card {
        margin: auto;
      }
    }
  }

  .feature-container.solution {
    padding-top: 0;
  }

  .features-card-container {
    height: 260px;
    padding: 1rem;
    border-radius: 25px;

    img {
      height: 50px;
      margin-top: 5px;
    }

    h2 {
      font-size: 17px;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
    }

    .tag {
      font-size: 10px;
    }
  }
}

.feature-container.feature-BG {
  padding-top: 15rem;
}

@media (min-width: 768px) and (max-width: 990px) {
  .feature-container.feature-BG {
    padding-top: 5rem;
  }
}