.comment {
    padding: 2rem 0 5rem;

    .card {
        background: #F5F5F5;
        max-width: 1000px;
        position: relative;
        border-radius: 80px;
        border: none;
    }
}

.testimonial_container {
    margin: 100px auto 60px;

    .img {
        max-height: 80px;
        width: 80px !important
    }
}

.content {
    max-width: 70%;
}

.quote {
    position: absolute;
    top: 30px;
    left: 230px;
}

.content-text {
    font-weight: 500;
    font-size: 1.2rem
}

.content-author {
    font-size: 1rem;
    margin-top: 15px
}

.content-user {
    font-weight: 600
}

.content-info {
    font-weight: 400
}

@media (max-width: 768px) {
    .comment {
        padding: 0 0 3rem;

        .card {
            border-radius: 30.065px;
        }
    }

    .content {
        max-width: 90%;
    }

    .quote {
        position: absolute;
        top: 20px;
        left: 89px;
    }

    .content-text {
        font-size: .8rem
    }

    .content-author {
        font-size: .5rem;
    }

    .testimonial_container {
        margin: 50px auto 35px;

        .img {
            width: 30px !important
        }
    }
}