@import "../variables";

@mixin footer-item {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.footer {
  position: absolute;
  z-index: 2;
  width: 100%;
  color: black;

  .size {
    font-size: 12px
  }

  .icon {
    width: 29px;
    height: 29px;
  }

  &--one {
    padding: 40px 0 50px 0;
  }

  &--two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
  }

  .footer-item-mobile {
    display: none;

    .footer-link {
      margin-right: 7px;
    }
  }

  .footer-item {
    @include footer-item();
    display: flex;
    margin-bottom: 0;
    align-items: center;
  }

  .footer-link {
    @include footer-item();

    &--active,
    &:hover {
      color: $stb-200-color;
    }
  }

  .scroll-button {
    cursor: pointer;
    margin-left: 10px;
  }

  a {
    color: $stb-100-color;
    text-decoration: none;
  }

  .footer-policy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  ul {
    li {
      margin-top: 1rem;
    }
  }

  h6 {
    font-size: 16px;
    letter-spacing: 0.042em;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .icons {
    display: flex;

    a {
      margin-right: 1.2rem;
    }
  }

  .follow-header {
    font-size: 16px;
  }

  div.icons,
  div.icons>* {
    font-size: 25px;
  }
}

@media (max-width: 990px) {
  .footer {
    .size {
      font-size: 10px
    }
  }
  .social-media img {
    width: 22px;
  }
}

@media (max-width: 767px) {
  .footer {
    &--one {
      padding: 150px 0 50px 0;
    }

    &--two {
      flex-direction: column;
    }

    .footer-policy {
      gap: 1rem;
    }

    .footer-item {
      display: none;
    }

    .footer--two {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    .footer-item-mobile {
      display: flex;
      align-items: start;
      flex-direction: column;
    }

    p.footer-item-mobile {
      margin-bottom: 0;
    }

    .space-top {
      margin-top: 3rem;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    &--one {
      padding: 50px 0 50px 0;
    }

    .size {
      font-size: 8px
    }
  }
}