@import "../variables";

@mixin contact-impact-bg {
    background: url("../../images/contact-us-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.2);
}

//BANNER
.contact-intro {
    color: #ffffff;
    @include contact-impact-bg;
}

.contact-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1 {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 70px;
        line-height: 118.6%;
    }

    .caption {
        font-weight: 400;
        font-size: 16px;
        line-height: 155.6%;
        color: #bac2c9;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1.8rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 160.6%;
        .heading {
            font-weight: 500;
        }
    }

    .address-section {
        max-width: 300px;
    }
}

.form-container {
    position: relative;
    color: #3a434b;
    background-color: #ffffff;
    margin: 3rem 0 0;
    box-shadow: 0px 4px 10px rgba(143, 148, 155, 0.25);
    border-radius: 16px;
    padding: 8rem 5rem;

    .contact-text {
        margin-top: 2rem;
        font-size: 16px;
        padding-right: 15%;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .input-field {
        height: 50px;
    }

    .form-error,
    .form-error:focus {
        border: 1px solid #dc3545;
        box-shadow: none;
    }

    .submit {
        margin-top: 4rem;
    }

    label {
        font-size: 12px;
        font-weight: 500;
    }
}

@media (max-width: 768px) {
    .form-container {
        padding: 8rem 2rem;
    }
}

@media (max-width: 576px) {
    .contact-intro {
        padding: 3rem 0;
    }
    .contact-text {
        h1 {
            margin-top: 60px;
            font-size: 40px;
        }
    }
}

@media (min-width: 1024px) {
    .contact-intro {
        min-height: 100vh;
    }
}

@media (min-width: 576px) and (max-width: 990px) {
    .contact-intro {
        padding: 10rem 0 3rem 0;
    }
}

@media screen and (min-width: 990px) and (max-width: 1280px) {
    .contact-intro {
        padding: 8rem 0 7rem 0;
    }
}

@media (min-width: 1280px) {
    .contact-intro {
        display: flex;
        justify-content: center;
        padding: 8rem 0 7rem 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .col-md-6 {
        width: 100% !important;
    }
}