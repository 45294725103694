.usage {
  margin: 0rem 2rem 0rem 2rem;
  padding: 4rem;
  border-radius: 50px;
  background: #F5F7FC;
}

.testimonial-card {
  color: #3e4462;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4rem 3rem;

  h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
  }
}

.testimonial-content {
  margin-bottom: 2rem;

  img {
    margin-top: 5px;
    width: 48px;
    width: 48px;
  }

  .caption {
    font-weight: 600;
    width: 95px;
    text-align: center;
  }
}

.testimonial-list {
  margin: auto;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.testimonial-text {

  font-weight: 600;
  font-size: 1.2rem;
  text-align: start;

  h3 {
    color: #2B55B7;
    font-size: 40px;
    font-weight: 700;
  }
}

.testimonial {
  border-radius: 34.381px;
  background: #E8EFFD;
  padding: 15px;
  height: 110px;
  width: 15%;
  margin: 5px;
  flex-direction: column;

  .play-button {
    width: 68px;
  }
}

.quote-text {
  font-size: 16px;
  color: #3e4462;
  margin-top: 1rem;
  line-height: 30px;
}

.person-icon-wrapper {
  width: 50px;
  height: 50px;
  background-color: #345dee;
  border-radius: 50%;
}

.author {
  font-size: 20px;
  color: #18214d;
  font-weight: bold;
}

.title {
  font-size: 14px;
  font-weight: 300;
}

@media (max-width: 990px) {
  .usage {
    padding: 4rem 0;
  }

  .testimonial-card {
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 2rem;
  }

  .testimonial-card {
    padding: 1.5rem 1rem;
  }

  .quote-text {
    margin-top: 1rem;
  }

  .testimonial {
    height: 100px;
    width: 23%;
    width: 30%;
    border-radius: 26px;
  }

  .sm-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .testimonial {
    height: 100px;
    width: 22%;
    border-radius: 25px;
    font-size: 1rem;
  }

  .usage {
    padding: 2rem 0;
  }
}

@media (max-width: 768px) {
  .testimonial-list {
    justify-content: flex-start;
  }
}

@media (max-width: 576px) {
  .testimonial-card {
    padding: 3rem 1rem;
  }

  .quote-text {
    font-size: 14px;
    line-height: 24px;
  }

  .author {
    font-size: 14px;
  }

  .testimonial {
    width: 30%;
    border-radius: 23.417px;
    font-size: .8rem;
  }

  .testimonial {
    .play-button {
      width: 30px;
    }
  }

  .testimonial-text {
    margin-top: 10px;
    font-size: 1rem;

    h3 {
      text-align: start;
      font-size: 22px;
      margin-top: 2rem;
    }
  }

  .title {
    font-size: 12px;
  }

  .usage {
    margin: auto;
    padding: 0;
    border-radius: 50px;
    background: #F5F7FC;
  }

  .testimonial-list {
    padding-bottom: 2rem;
    justify-content: space-between;
  }
}