@import "../variables";


.mobile-container {
  margin: 2rem auto;
}

.mobile-BG {
  background-image: url('../../images/Home/mobile-left.png');
  background-position: 0 12rem;
  background-repeat: no-repeat;
}

.mobile-text {
  color: #2B55B7;
  font-size: 40px;
  font-weight: 700;
  line-height: 94%;
  margin: 0 0 25px;
}

.mobile-caption {
  font-size: 1.2rem;
  font-weight: 400;
}

.card-mobile-BG {
  background-image: url('../../images/Home/mobile-right.png');
  background-position: bottom right;
  background-repeat: no-repeat;
}

.barcode-BG {
  background-image: url('../../images/Home/barcode-right.png');
  background-position: right bottom;
  background-position-y: calc(100% + 65px);
  background-repeat: no-repeat;
  padding-top: 8rem;
  padding-bottom: 2rem;
  margin: auto;
}

.barcode-box {
  background-color: #F5F7FC;
  padding: 30px;
  border-radius: 40px;
  height: 316px;
  width: 316px;
  margin: auto;
}

@media (max-width: 990px) {
  .mobile-container {
    text-align: center;
  }

  .mockup-section {
    gap: 1.5rem;
    text-align: center;
  }

}

@media (max-width: 576px) {
  .mobile-text {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .mobile-BG {
    background-image: url('../../images/Home/mobile-left.png');
    background-position: -6rem 2rem;
    background-repeat: no-repeat;
  }

  .mobile-container img {
    max-width: 225px;
  }

  .barcode-BG {
    background: none;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }

  .barcode-box {
    padding: 15px;
    border-radius: 20px;
    height: 158px;
    width: 158px;
  }

  .barcode-BG img {
    max-width: 175px;
  }

  .card-mobile-BG {
    background: none;
  }

  .mobile-container {
    &.mobile-BG {
      padding-top: 5rem !important;
    }
  }
}

.mobile-container.mobile-BG {
  padding-top: 10rem;
}

@media (min-width: 768px) and (max-width: 990px) {
  .mobile-container.mobile-BG {
    padding-top: 5rem;
  }
}