@import "../variables";

.solution-card {
    position: relative;
}

.solution-top-tag {
    position: absolute;
    top: -13rem;
    left: 0;
    z-index: 1 !important;
}

.solution-bottom-tag {
    position: absolute;
    bottom: -21rem;
    right: 0;
    z-index: 1 !important;
}

.experience-card {
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    position: relative;
    border-radius: 0 80px 80px 0;
    z-index: 200000 !important;


    .about-card {
        padding: 1rem auto;
        justify-content: space-between;

        .goal-text {
            width: 40%;
            color: #ffffff;
            padding-left: 5rem;
            margin-top: -40px;

            img {
                margin-bottom: 2rem;
            }

            h3 {
                font-size: 3.5rem;
                font-weight: 700;
                margin-bottom: 2rem;
                max-width: 385px;
            }

            p {
                font-weight: 200;
                font-size: 18px;
                line-height: 30px;
            }
        }


        .goal-image-container {
            margin: 0;
            padding: 0;
            text-align: end;
            width: 45%;

            img.right {
                border-radius: 0 80px 80px 0;
            }

            img.left {
                border-radius: 80px 0 0 80px;
            }
        }
    }

    .solution-bg {
        background-image: url('../../images/Home/solution-left.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-position-y: calc(100% + 20px);
        position: absolute;
        margin-bottom: -20px;
    }
}

@media (max-width: 767px) {
    .experience-card {
        border-radius: 0 58px 58px 0;
    }

    .about-card .goal-text {
        margin-top: 0;
        width: 100%;
    }
}