@import "../variables";

.get-started {
    margin: 7rem auto 5rem;
}


.fmd-block {
    display: block;
}

.fxs-none {
    display: none;
}

.vl-button--lg.button-xs {
    padding: 3px 7px;
    font-size: 8px;
}


.footer-container {
    border-radius: 40px;
    background: #F5F7FC;
    background-image: url('../../images/Home/footerbgimage.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    h3 {
        color: #2B55B7;
        font-size: 40px;
        font-weight: 700;
    }

    .footer-image {
        width: 45%;

        img {
            width: 100%;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
        }
    }

    .footer-text {
        width: 55%;
    }

    .pricing-nav {
        margin-left: 30px;
        font-size: 17px;
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    .footer-container {
        h3 {
            text-align: center;
            font-size: 14px;
        }
    }


    .footer-container {
        border-radius: 13.75px;
        background-image: url('../../images/Home/footerxsimage.png');

        .footer-image {
            width: 45%;

            img {
                border-top-left-radius: 13.75px;
                border-bottom-left-radius: 13.75px;
                height: auto;
            }
        }

        .pricing-nav {
            margin-left: 10px;
            font-size: 8px;
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 990px) {
    .fxs-none {
        display: block;
    }

    .fmd-block {
        display: none;
    }

    .footer-container h3 {
        font-size: 28px;
    }

    .vl-button--lg.button-xs {
        font-size: 15px;
    }

    .footer-container .pricing-nav {
        font-size: 16px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .footer-container h3 {
        font-size: 25px;
    }

    .vl-button--lg.button-xs {
        font-size: 13px;
    }

    .footer-container .pricing-nav {
        font-size: 13px;
    }
}