@import "../variables";

@mixin blue-bg {
    background: url("../../images/bg-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main {
    @include blue-bg;
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    min-height: calc(100vh - 6rem);

    .parent {
        padding: 4rem 5px 3rem;
        max-width: 345px;

        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
        }

        h3 {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            margin: 20px auto;
        }

        .auth-box {
            background: rgba(64, 123, 255, 0.04);
            border: 1px solid #407bff;
            border-radius: 200px;
            color: #2e353a;
            cursor: pointer;
            max-width: 333px;
            padding: 10px 14px;
            margin: 13px auto;
            img {
                margin-right: 5px;
            }
        }
    }
}

.inputs {
    input {
        border: 1px solid #bababa;
        border-radius: 200px;
        color: #909090;
        max-width: 333px;
        padding: 10px 14px;
        margin: 13px auto;

        &:hover,
        &:active,
        &:focus {
            border: 1px solid #407bff;
            color: #2e353a;
        }
    }
    button {
        width: 333px;
        background-color: #407bff;
        color: white;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 10px auto;
    }
    .forgot-password {
        color: #407bff;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: start;
        margin: 10px 0;
    }
    .terms {
        margin: 10px 0;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #263238;
    }
}

@media (max-width: 576px) {
    .main {
        margin-top: 3rem;
        min-height: calc(100vh - 3rem);
    }
}